import React from 'react';
import {useTranslation} from 'react-i18next';
import StatusItem from '../../StatusItem';
import { DATE_FORMAT } from '../../../config';
import { format, utcToZonedTime } from 'date-fns-tz';
import
{
  ILocation,
  IAnalysisOverview,
} from '../../../models';
import './styles.scss';

export interface IOverviewProps {
  analysisOverview: IAnalysisOverview;
}

export const Overview: React.FunctionComponent<IOverviewProps> = (props) => {
  const { t: _t } = useTranslation()
  const t = (key: string) => _t(`analyseResultPage.${key}`)

  const { analysisOverview } = props;

  const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const getTitle = () => {
    if(analysisOverview.condition.locations) {
      return t('locations')
    } else if(analysisOverview.condition.posts) {
      return t('sns')
    } else if(analysisOverview.condition.gbp_posts) {
      return t('gbp')
    }
  }

  return (
    <div className="result-overview">
      <div className="top-uuid">
        {analysisOverview.uuid}
      </div>
      <div className="white-border-panel">      
        <div className="table-data">
          {
            <div className="row-td" >
              <div className="col-td width10">
                <div className="spacing">
                  <StatusItem
                    status={analysisOverview.status}
                    />
                  <div className="bottom-btn ml30">
                    <a href="#javascript" className='btn btn-border lang-tag not-clickable'>
                      {analysisOverview.lang}
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-td width65">
                <div className="spacing">
                  <div className="top-location bold">
                    <div className="txt">
                      {getTitle()}
                    </div>
                    {
                      analysisOverview.condition.locations && analysisOverview.condition.locations.map((itemLocation: ILocation, indexLocation: number) => (
                        <div className="bottom-txt" key={indexLocation}>
                          <i className='points'></i>
                          <span className='txt bold'>{itemLocation.name}</span>
                        </div>
                      ))
                    }
                    {
                      analysisOverview.condition.posts && analysisOverview.condition.posts.map((post: any, indexPost: number) => (
                        <div className="bottom-txt" key={indexPost}>
                          <i className='points'></i>
                          <span className='txt bold'>{`${post?.sns_type} ${post.post_id}`}</span>
                        </div>
                      ))
                    }
                    {
                      analysisOverview.condition.gbp_posts && analysisOverview.condition.gbp_posts.map((post: any, indexPost: number) => (
                        <div className="bottom-txt" key={indexPost}>
                          <i className='points'></i>
                          <span className='txt bold'>{`${post.post_id}`}</span>
                        </div>
                      ))
                    }
                  </div>
                </div>
              </div>
              <div className="col-td width21">
                <div className="spacing">
                  <div className="row-line">
                    <span className='gray-at'>
                      {t('created_at')}
                    </span>
                    <span className="txt time">
                      {format(utcToZonedTime(new Date(analysisOverview.created_at), currentTimeZone), DATE_FORMAT)}
                    </span>
                  </div>
                  {((analysisOverview.status === 'completed') || (analysisOverview.status === 'error')) && (
                    <div className="row-line">
                      <span className='gray-at'>
                        {t('completed_at')}
                      </span>
                      <span className="txt time">
                        {format(utcToZonedTime(new Date(analysisOverview.completed_at), currentTimeZone), DATE_FORMAT)}
                      </span>
                    </div>
                  )}
                  {(analysisOverview.status === 'completed') && (
                    <div className="row-line">
                      <span className='gray-at'>
                        {t('number_of_reviews')}
                      </span>
                      <span className="txt">
                        {analysisOverview.number_of_reviews}
                      </span>
                    </div>
                  )}
                  {(analysisOverview.condition.start_date && analysisOverview.condition.end_date) && (
                    <div className="row-line">
                      <span className='gray-at'>
                        {t('period')}
                      </span>
                      <span className="txt time">
                        {
                        `${format(utcToZonedTime(new Date(analysisOverview.condition.start_date), currentTimeZone), DATE_FORMAT)}
                         -
                         ${format(utcToZonedTime(new Date(analysisOverview.condition.end_date), currentTimeZone), DATE_FORMAT)}
                         `
                        }
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default Overview;
