import React, {useEffect, useState} from 'react';
import { useQuery } from '@tanstack/react-query';
import { ToastContainer, toast } from 'react-toastify';
import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {NavLink} from 'react-router-dom';
import nprogress from 'accessible-nprogress';
import DataSvc from '../../services/dataSvc';
import dataAction from '../../actions/dataAction';
import { delay } from '../../utils';
import Header from '../../components/Header';
import Overview from '../../components/AnalysisResultComponents/Overview';
import Stats from '../../components/AnalysisResultComponents/Stats';
import Wordcloud from '../../components/AnalysisResultComponents/Wordcloud';
import NLPAnalysis from '../../components/AnalysisResultComponents/NLPAnalysis';

import './styles.scss';
import Footer from '../../components/Footer';

interface IAnalysisResultPageProps {
  // organization?: OrganizationResponse;
  dataAction?: any;
}

const AnalysisResultPage: React.FunctionComponent<IAnalysisResultPageProps> = (props) => {  
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`analyseResultPage.${key}`);
  
  const params = useParams();
  const uuid = params.uuid || '';
  
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  const [categoryType, setCategoryType] = useState<string>('rating'); // 'rating', 'ai'
  const [criteriaType, setCriteriaType] = useState<string>('all'); // 'all', '1to3', '4to5', 'good', 'bad'
  
  useEffect(() => {
    nprogress.configure({parent: 'main'});
    nprogress.start();
  }, [props.dataAction]);
  
  const { data: analysisOverview } = useQuery(['analysisOverview'], async () => {
    try {
      const res = await DataSvc.getAnalysisOverviewData(uuid);
      return res;
    } catch(e) {
      toast.error(`${_t('error.fetching_analysis_overview')}: \n${e?.toString()}`, {
        autoClose: 5000,
      });
      
      await delay(5000);
      return null;
    }
  });
  
  const { data: analysisResult } = useQuery(['analysisResult', categoryType, criteriaType], async () => {
    try {
      nprogress.start();
      const res = await DataSvc.getAnalysisResultData(uuid, categoryType, criteriaType);
      nprogress.done();
      
      setTimeout(() => {
        if (!isFirstLoad) {
          const element = document.getElementById("result-nlp-analysis");
          element?.scrollIntoView({behavior: "auto",  inline: "nearest"});
        }
        setIsFirstLoad(false);
      }, 100);
      
      return res;
    } catch(e) {
      toast.error(`${_t('error.fetching_analysis_result')}: \n${e?.toString()}`, {
        autoClose: 5000,
      });
      
      await delay(5000);
      return null;
    }
  });
  
  const { data: analysisStats } = useQuery(['analysisStats'], async () => {
    try {
      return await DataSvc.getAnalysisStatsData(uuid);
    } catch(e) {
      toast.error(`${_t('error.fetching_analysis_stats')}: \n${e?.toString()}`, {
        autoClose: 5000,
      });
      
      await delay(5000);
      return null;
    }
  });
  
  const { data: analysisWordcloud } = useQuery(['analysisWordcloud'], async () => {
    try {
      return await DataSvc.getAnalysisWordcloudData(uuid);
    } catch(e) {
      toast.error(`${_t('error.fetching_analysis_wordcloud')}: \n${e?.toString()}`, {
        autoClose: 5000,
      });
      
      await delay(5000);
      return null;
    }
  });

  nprogress.done();

  return (
    <React.Fragment>
      <Header />
      
      <ToastContainer />
      
      <div className="analyses-result-page">
        <div className="top-back flex">
          <NavLink to={'/analyses'} className='icons icon-back'>
            {t('return_to_analyses')}
          </NavLink>
        </div>
        
        {!!analysisOverview && (
          <Overview 
            analysisOverview={analysisOverview}
            />
        )}
        
        <Stats 
          analysisStats={analysisStats} />
        
        {!!analysisWordcloud && (
          <Wordcloud 
            analysisWordcloud={analysisWordcloud}/>
        )}
        
        {analysisResult && (
          <NLPAnalysis 
            categoryType={categoryType}
            criteriaType={criteriaType}
            analysisResult={analysisResult}
            onClickTabs={(categoryType: string, criteriaType: string) => {
              setCategoryType(categoryType);
              setCriteriaType(criteriaType);
            }}
             />
        )}
      </div>

      <Footer />
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => ({...state.dataReducer});

const matchDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators({}, dispatch),
  dataAction: bindActionCreators({...dataAction}, dispatch),
});

export default connect(mapStateToProps, matchDispatchToProps)(AnalysisResultPage);
