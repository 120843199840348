/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react';
import { useQuery } from '@tanstack/react-query';
import { ToastContainer, toast } from 'react-toastify';
import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import { useTranslation } from 'react-i18next';
import {NavLink} from 'react-router-dom';
import nprogress from 'accessible-nprogress';
import DataSvc from '../../services/dataSvc';
import dataAction from '../../actions/dataAction';
import { delay } from '../../utils';
import { STATUS_POLL_INTERVAL } from '../../config';
import Header from '../../components/Header';
import ModalNewAnalysis from '../../components/AnalysesComponents/ModalNewAnalysis';
import ModalSnsAnalysis from '../../components/AnalysesComponents/ModalSnsAnalysis';
import AnalysesList from '../../components/AnalysesComponents/AnalysesList';
import { SnsType } from '../../constants/snsTypes'
import
{
  IAnalysisOverview,
  IAnalysesFilterFormData,
  IAnalyseAddFormData,
} from '../../models';
import './styles.scss';
import Footer from '../../components/Footer';

const defaultFilterFormData: IAnalysesFilterFormData = {
  reloading: false,
  numberPerPage: 10,
  pageIndex: 1,
};
interface IAnalysesPageProps {
  dataAction?: any;
}

const AnalysesPage: React.FunctionComponent<IAnalysesPageProps> = (props) => {  
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`analysesPage.${key}`);
  
  useEffect(() => {
    nprogress.configure({parent: 'main'});
    nprogress.start();
  }, [props.dataAction]);
  
  const [totalAnalyses, setTotalAnalyses] = useState<number>(0);
  const [analysesListData, setAnalysesListData] = useState<IAnalysisOverview[]>([]);
  const [shownModalNewAnalysis, setShownModalNewAnalysis] = useState<boolean>(false);
  const [shownModalSnsAnalysis, setShownModalSnsAnalysis] = useState<boolean>(false);
  const [filterFormData, setFilterFormData] = useState<IAnalysesFilterFormData>(defaultFilterFormData);
  const [uuidsArray, setUuidsArray] = useState<string[]>([]);

  const { data: analysesList } = useQuery(['analysesList', filterFormData], async () => {
    try {
      nprogress.start();
      return await DataSvc.getAnalysesData((filterFormData.pageIndex - 1) * filterFormData.numberPerPage,
                                     filterFormData.numberPerPage);
    } catch(e) {
      toast.error(`${_t('error.fetching_analyses_list')}: \n${e?.toString()}`, {
        autoClose: 5000,
      });
      
      await delay(5000);
      return null;
    }
  });
  
  useEffect(() => {
    if(analysesList && analysesList.data.length !== 0) {
      setTotalAnalyses(analysesList?.total);
      setAnalysesListData(analysesList?.data);
      
      const uuidsArrayTemp: string[] = [];
      (analysesList?.data.slice(0, filterFormData.numberPerPage) || []).forEach((item: IAnalysisOverview) => {
        if (item.status === 'waiting'
         || item.status === 'processing') {
          uuidsArrayTemp.push(item.uuid);
        }
      });
      
      setUuidsArray(uuidsArrayTemp);
    }
    // eslint-disable-next-line
  }, [analysesList]);
  
  useEffect(() => {
    if (uuidsArray.length === 0) {
      return;
    }
    
    let timeoutId: any;
    
    const fetchData = async () => {
      const res = await DataSvc.getAnalysesStatusData(uuidsArray);
      
      timeoutId = setTimeout(() => {
        const uuidsArrayTemp: string[] = [];
        (analysesListData || []).forEach((item: IAnalysisOverview) => {
          (res || []).forEach((updatedItem: IAnalysisOverview) => {
            if (item.uuid === updatedItem.uuid) {
              if (item.status !== updatedItem.status) {
                item.status = updatedItem.status;
                item.completed_at = updatedItem.completed_at;
                
                if (updatedItem.status === 'completed') {
                  toast.success(<div>
                                  <>
                                    {_t('success.analyse_is_completed')}: <br/>
                                    <NavLink to={`/analyses/${updatedItem.uuid}`}>{updatedItem.uuid}</NavLink>
                                  </>
                                </div>, {
                    autoClose: 5000,
                  });
                }
                if (updatedItem.status === 'error') {
                  toast.error(<div>
                                 <>
                                   {_t('error.analyse_gets_error')}: <br/>
                                   {updatedItem.uuid}
                                 </>
                               </div>, {
                    autoClose: 5000,
                  });
                }
              }
              
              if (updatedItem.status === 'waiting'
               || updatedItem.status === 'processing') {
                uuidsArrayTemp.push(updatedItem.uuid);
              }
            }
          });
        });
      
        setUuidsArray(uuidsArrayTemp);
      }, STATUS_POLL_INTERVAL);
    }

    fetchData().then(r => {});
    
    return () => {
      clearTimeout(timeoutId);
    };
    
    // eslint-disable-next-line
  }, [uuidsArray])
  
  // on Start New Analysis
  const onStartNewAnalysis = async (formData: IAnalyseAddFormData) => {
    const languageArray = [];

    if (formData.languageEn) {
      languageArray.push('en');
    }
    if (formData.languageJa) {
      languageArray.push('ja');
    }

    const payload = {
      condition: {
        ...formData,
        languages: languageArray,
      }
    }

    try {
      nprogress.start();
      const res = await DataSvc.postAnalysesData(payload);
      nprogress.done();

      setFilterFormData({
        ...filterFormData,
        reloading: !filterFormData.reloading
      });

      toast.success(t('success_add_new_analysis'), {
        autoClose: 5000,
      });

      await delay(5000);

      return res;
    } catch(e) {
      toast.error(`${t('add_new_analysis')}: \n${e?.toString()}`, {
        autoClose: 5000,
      });

      await delay(5000);
      return null;
    }
  };

  const onStartNewSnsAnalysis = async (snsType: SnsType, formData: FormData) => {
    try {
      let res = null;
      nprogress.start();
      if (snsType === SnsType.GBP) {
        res = await DataSvc.postGbpAnalysesData(formData);
      } else if (snsType === SnsType.TWITTER || snsType === SnsType.IG) { 
        res = await DataSvc.postSnsAnalysesData(formData);
      }
    
      nprogress.done();

      toast.success(t('success_add_new_analysis'), {
        autoClose: 5000,
      });

      await delay(5000);

      return res;
    } catch(e) {
      toast.error(`${t('add_new_analysis')}: \n${e?.toString()}`, {
        autoClose: 5000,
      });

      await delay(5000);
      return null;
    }
  }

  nprogress.done();

  return (
    <React.Fragment>
      <Header />
      
      <ToastContainer />
      
      <div className="analyses-page">
        <div className="top-bar flex-grid">
          <div className="title">
            {t('analyses')}
          </div>
          <div className='right-buttons-container'>
            <div className="right-btn">
              <a
                className='btn btn-border'
                onClick={() => {
                  setShownModalNewAnalysis(true);
                }}>
                <i className='icons icon-add'></i>
                {t('new_analysis')}
              </a>
            </div>
            <div className="right-btn">
              <a
                className='btn btn-border'
                onClick={() => {
                  setShownModalSnsAnalysis(true);
                }}>
                <i className='icons icon-add'></i>
                {t('new_sns_analysis')}
              </a>
            </div>
          </div>
        </div>
        
        {!!analysesList && (
          <AnalysesList
            dataList={analysesListData}
            totalCount={totalAnalyses}
            filterFormData={filterFormData}
            onChangeFilterFormData={(
              filterFormData: IAnalysesFilterFormData
            ) => {
              setFilterFormData(filterFormData);
            }}
          />
        )}
      </div>

      <Footer />
      
      {shownModalNewAnalysis && (
        <ModalNewAnalysis
          onClose={() => {
            setShownModalNewAnalysis(false);
          }}
          onSave={(formData: IAnalyseAddFormData) => {
            onStartNewAnalysis(formData);
            setShownModalNewAnalysis(false);
          }}
        />
      )}

      {shownModalSnsAnalysis && (
        <ModalSnsAnalysis
          onClose={() => {
            setShownModalSnsAnalysis(false);
          }}
          onSave={(snsType, formData: FormData) => {
            onStartNewSnsAnalysis(snsType, formData);
            setShownModalSnsAnalysis(false);
          }}
        />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => ({...state.dataReducer});

const matchDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators({}, dispatch),
  dataAction: bindActionCreators({...dataAction}, dispatch),
});

export default connect(mapStateToProps, matchDispatchToProps)(AnalysesPage);
